import { Footnotes, FootnotesProvider, FootnoteRef as Ref } from "../footnotes"

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import FootnotesBackLink from "../components/FootnotesBackLink"
import LastUpdated from "../components/LastUpdated"
import Layout from "../components/Layout"
import React from "react"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

// import { graphql, useStaticQuery } from "gatsby"


const AdsPage = ({pageContext}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  let crumbs_mod = crumbs.slice(0);

  crumbs_mod.splice(-1, 0, {pathname: '/media', crumbLabel: 'Media'});

  return (
    <Layout >
      <Seo title="Talman catalogs" />

      <div className="relative max-h-60vh max-w-1920 bg-black mx-auto lg:flex lg:items-center">
          <div className="relative z-0 w-full max-h-60vh overflow-hidden">
            <StaticImage layout="fullWidth" className="h-full block" src="../assets/images/catalogs/catalogs-banner.jpg" alt="1998 Ibanez catalog open to Talman pages" quality={75}  />
          </div>

          <h1 className="absolute z-10 page-heading pl-5 lg:pl-12 bottom-0 left-0  leading-3">Talman<br /> catalogs</h1>
          <div className="absolute z-0 left-0 top-0 w-full h-full bg-gradient-to-t from-black via-transparent to-black" />
      </div>

      <FootnotesProvider>
      <div className="max-w-1920 mx-auto p-5 lg:p-12 pt-0 lg:pt-0">
        <Breadcrumb crumbs={crumbs_mod} crumbLabel="Catalogs" crumbSeparator="»" />

        <section className=" lg:mx-auto">
          <h2 className="mt-12 lg:mt-24 font-bold text-2xl lg:text-5xl">Checkout the <Ref description="The catalog scans Ibanez provides are nice, but not perfect. In some cases you can see where holes were punched through the catalog (for storage in a 3-ring binder), thus destroying/obscuring some of the original page. If for any reason the official scans disappear, I'll do my best to make them available again. Additionally, I might also take better scans of the specific Talman related pages and make them available here.">official Ibanez catalog scans!</Ref></h2>
          <p className="text-gray-400 text-sm lg:text-lg">We are super lucky that Ibanez provides this resource! Very few manufacuturers do this.</p>
        
          <ul className="text-2xl lg:text-4xl mt-10 lg:list-disc dark-marker lg:mx-8">
          <li className="mb-10"><a href="https://www.ibanez.com/usa/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">USA catalogs <span className="text-xs lg:text-sm">(1960-2023)</span></a>
              
              <h3 className="font-serif font-bold mt-12">Vintage / debut Talman models (1994-1998)</h3>
              <ul className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-8">
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1994USA.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1994usa_0.jpg" alt="1994 Ibanez catalog USA cover" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">1994 Ibanez catalog (USA) [PDF]</div>
                  </a>
                  <div className="note">Talman models p. 18-21</div>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1995USA.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1995usa_0.jpg" alt="1995 Ibanez catalog USA cover" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">1995 Ibanez catalog (USA) [PDF]</div>
                  </a>
                  <div className="note">Talman on cover, p. 2-3, Talman models p. 6-7</div>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1996USA.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1996usa_0.jpg" alt="1996 Ibanez catalog USA cover" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">1996 Ibanez catalog (USA) [PDF]</div>
                  </a>
                  <div className="note">Talman models p. 8-9</div>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1997USA.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1997usa_0.jpg" alt="1997 Ibanez catalog USA cover" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">1997 Ibanez catalog (USA) [PDF]</div>
                  </a>
                  <div className="note">Talman models p. 14-15, Talman on p. 36</div>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1998EG_USA.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1998usa_0.jpg" alt="1998 Ibanez catalog USA cover" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">1998 Ibanez catalog (USA) [PDF]</div>
                  </a>
                  <div className="note">Talman models p. 28-29</div>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1995Talman.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1995_Talman_01.jpg" alt="scan of Product News page featuring Talman guitars" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">Hoshino Product News - Talman TC830FM, TC420 - 1995 [PDF]</div>
                  </a>
                </li>
                <li className="catalog-card">
                  <a className="" href="http://www.hoshinogakki.co.jp/pdf/ibanez/catalog/1995Talman_TC820_TC830.pdf" target="_blank" rel="noopener noreferrer nofollow">
                    <StaticImage className="h-full" src="../assets/images/catalogs/usa/1995_Talman_TC820_TC830_01.jpg" alt="scan of Product News page featuring top of the line Talman guitars" placeholder="dominantColor" layout="fullWidth" quality={75} />
                    <div className="title">Hoshino Product News - Talman TC820, TC830 - 1995 [PDF]</div>
                  </a>
                  <div className="note"><Ref description="The Ibanez website lists this Product News scan under the USA region, but note the TC830FM is the only model that was available in the USA. All other models mentioned (TC820, TC820FM, TC830) were available outside the USA.">TC820, TC830*</Ref></div>
                </li>
              </ul>
            </li>
            
            <li className="mb-10"><a href="https://www.ibanez.com/asia/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">AA (Asia &amp; Africa) catalogs <span className="text-xs lg:text-sm">(1960-2023)</span></a></li>
            <li className="mb-10"><a href="https://www.ibanez.com/cn/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">CN (China) catalogs <span className="text-xs lg:text-sm">(2018-2021)</span></a></li>
            <li className="mb-10"><a href="https://www.ibanez.com/eu/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">EU (Europe) catalogs <span className="text-xs lg:text-sm">(1960-2023)</span></a></li>
            <li className="mb-10"><a href="https://www.ibanez.com/jp/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">JP (Japan) catalogs <span className="text-xs lg:text-sm">(1979-2021)</span></a></li>
            <li className="mb-10"><a href="https://www.ibanez.com/na/support/catalogs/" target="_blank" rel="noopener noreferrer nofollow">NA (North &amp; South America and Oceania) catalogs <span className="text-xs lg:text-sm">(1960-2023)</span></a></li>
            
          </ul>
        
        </section>
        
      </div>

      <Footnotes BackLink={FootnotesBackLink} />
      <LastUpdated date={'2023-03-03'} />
      </FootnotesProvider>
    </Layout>
  )
}

export default AdsPage