import React from "react"

// NOTE: date is an ISO date string (from mdx)
const LastUpdated = ({date}) => {
    return <div className="last-updated">
        Last updated: <span className="date">{date.split('T')[0]}</span>
    </div>;
}

export default LastUpdated
